exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookiepage-tsx": () => import("./../../../src/pages/cookiepage.tsx" /* webpackChunkName: "component---src-pages-cookiepage-tsx" */),
  "component---src-templates-activity-group-template-index-tsx": () => import("./../../../src/templates/activityGroupTemplate/index.tsx" /* webpackChunkName: "component---src-templates-activity-group-template-index-tsx" */),
  "component---src-templates-activity-template-index-tsx": () => import("./../../../src/templates/activityTemplate/index.tsx" /* webpackChunkName: "component---src-templates-activity-template-index-tsx" */),
  "component---src-templates-age-group-template-index-tsx": () => import("./../../../src/templates/ageGroupTemplate/index.tsx" /* webpackChunkName: "component---src-templates-age-group-template-index-tsx" */),
  "component---src-templates-content-page-template-index-tsx": () => import("./../../../src/templates/contentPageTemplate/index.tsx" /* webpackChunkName: "component---src-templates-content-page-template-index-tsx" */),
  "component---src-templates-front-page-template-index-tsx": () => import("./../../../src/templates/frontPageTemplate/index.tsx" /* webpackChunkName: "component---src-templates-front-page-template-index-tsx" */)
}

